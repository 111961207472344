<template>
    <div class="c-jobs user-content flex">
        <div class="job-list-warpper">
            <div class="job-list">
                <div class="job-item flex align-center justify-between" v-for="(item, index) in jobList" :key="`jobList${index}`" @click="roteToJobDetail(item)">
                    <div style="width: 700px;">
                        <div class="job-item-data flex">
                            <div class="left-title text-overflow">{{item.workName}}</div>
                            <div class="right-sales">{{item.wagesName}}</div>
                        </div>
                        <div class="job-item-detail flex">
                            <div class="item-detail-desc">{{item.workYears}} / {{item.workEducation}} / 全职</div>
                            <div class="item-detail-time">{{item.createTime.split(' ')[0]}} 发布</div>
                        </div>
                    </div>
                    <div>
                        <el-button type="primary" plain style="height: 60px;" @click="gotoDating(item)">面试大厅</el-button>
                    </div>

                </div>
                <app-pagination @handleCurrentChange="handleCurrent" :total="jobTotal"></app-pagination>
            </div>
        </div>
        <div class="base-info-warpper" style="margin-left: 15px;">
            <div class="info-basic">
                <div class="basic-title text-overflow">{{companyInfo.companyName}}</div>
                <div class="basic-content">
                    <div class="content-item">
                        <i class="basic-icon type"></i><span>{{companyInfo.companyIndustryName}}</span>
                    </div>
                    <div class="content-item">
                        <i class="basic-icon number"></i><span>{{companyInfo.companyScale}}</span>
                    </div>
                    <div class="content-item">
                        <i class="basic-icon address"></i><span>{{companyInfo.companyAreaName}}</span>
                    </div>
                </div>
            </div>
            <div class="company_bussiness">
                <div class="bussiness-title">工商信息</div>
                <div class="bussiness-item">
                    <div class="bussiness-item-title">
                        <i class="bussiness-icon magerinfo"></i><span>企业性质</span>
                    </div>
                    <div class="title-data text-overflow">{{companyInfo.companyNatureName}}</div>
                </div>
                <div class="bussiness-item">
                    <div class="bussiness-item-title">
                        <i class="bussiness-icon money"></i><span>注册资金</span>
                    </div>
                    <div class="title-data">{{companyInfo.register}}万元人民币</div>
                </div>
                <div class="bussiness-item">
                    <div class="bussiness-item-title">
                        <i class="bussiness-icon magerinfo langer"></i><span>招聘负责人代表</span>
                    </div>
                    <div class="title-data">{{companyInfo.legalName}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userRequest from '../../../api/user'
import AppPagination from '../../../components/pagination/AppPagination'
export default {
    components: {
        AppPagination
    },
    mounted() {
        let query = this.$route.query
        if (!query.companyId) {
            this.$router.back()
            return
        }
        this.companyId = query.companyId
        this.getCompanyDetail()
        this.jobForm.memberId = query.companyId
        this.getJobListByCompanyId()
    },
    data() {
        return {
            companyId: null,
            companyInfo: {},
            jobForm: {memberId: null, pageNum: 1, pageSize: 10},
            jobList: [],
            jobTotal: 0
        }
    },
    methods: {
        gotoDating(item){//去大厅界面
            if(item.joinUserNumber>=8){
                this.$message.warning("面试大厅人数已满！")
                return
            }

            const {href} = this.$router.resolve({
                path: '/user/tempInterview',
                query: {
                    workId: item.id,
                }
            })
            window.open(href, '_blank')
        },
        getCompanyDetail() {
            userRequest.getCompanyDetail({memberId: this.companyId}).then((res) => {
                if (res.code === 200) {
                    this.companyInfo = res.data
                    const address = res.data.companyAreaName.split('-')
                    this.address = address[address.length - 1] + res.data.companyAddress
                    console.log('address', this.address)
                }
            })
        },
        getJobListByCompanyId() {
            userRequest.getJobListByCompanyId(this.jobForm).then((res) => {
                if (res.code === 200) {
                    this.jobList = res.data.list
                    this.jobTotal = res.data.total
                }
            })
        },
        handleCurrent(val) {
            this.jobForm.pageNum = val
            this.getJobListByCompanyId()
        },
        roteToJobDetail(item) {
            this.$router.push({
                path: '/user/jobDetail',
                query: {
                    workId: item.id,
                    workName: item.workName
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";
    .c-jobs{
        height: 100%;
        .text-overflow{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .job-list-warpper{
            padding-top: 20px;
            .job-list{
                width: 836px;
                .job-item{
                    cursor: pointer;
                    height: 80px;
                    padding: 15px 16px;
                    margin-bottom: 20px;
                    background: #fff;
                    border: 1px solid #ededed;
                    font-size: 14px;
                    &:hover{
                        background: #fafafa;
                    }
                    .job-item-data{
                        justify-content: space-between;
                        margin-bottom: 15px;
                        .left-title{
                            font-size: 16px;
                            color: $green;
                            font-weight: 700;
                            max-width: 390px;
                        }
                        .right-sales{
                            font-size: 16px;
                            color:$main;
                            font-weight: 700;
                        }
                    }
                    .job-item-detail{
                        justify-content: space-between;
                        font-size: 14px;
                        color: #999;
                    }
                }
            }
        }
        .base-info-warpper{
            box-sizing: border-box;
            width: 357px;
            margin-left: 5px;
            margin-top: 20px;
            .info-basic{
                padding: 28px 20px 16px 20px;
                margin-bottom: 20px;
                background: #fff;
                .basic-title{
                    font-size: 18px;
                    color: #000;
                    margin-bottom: 20px;
                }
                .basic-content{
                    padding-left: 20px;
                    .content-item{
                        margin-bottom: 18px;
                        .basic-icon{
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                            margin-right: 12px;
                            vertical-align: middle;
                            &.type{
                                background: url('../../../assets/img/users/type_icon_1.png') no-repeat;
                            }
                            &.number{
                                background: url('../../../assets/img/users/number_icon_3.png') no-repeat;
                            }
                            &.address{
                                background: url('../../../assets/img/users/address_icon_4.png') no-repeat;
                            }
                        }
                        span{
                            position: relative;
                            font-size: 14px;
                        }
                    }
                }
            }
            .company_bussiness{
                background: #fff;
                padding: 20px 20px;
                .bussiness-title{
                    font-size: 18px;
                    color: #000;
                    min-width: 300px;
                    margin-bottom: 20px;
                }
                .bussiness-item{
                    margin-left: 20px;
                    margin-bottom: 18px;
                    .bussiness-item-title{
                        .bussiness-icon{
                            display: inline-block;
                            width: 18px;
                            height: 18px;
                            &.magerinfo{
                                background: url('../../../assets/img/users/company-fix.png');
                                background-size: 100% 100%;
                            }
                            &.money{
                                background: url('../../../assets/img/users/clock.png');
                                background-size: 100% 100%;
                            }
                            &.langer{
                                background: url('../../../assets/img/users/langer.png');
                                background-size: 100% 100%;
                            }
                        }
                        span{
                            position: relative;
                            margin-left: 12px;
                            top: -3px;
                        }
                    }
                    .title-data{
                        padding-top: 2px;
                        font-size: 14px;
                        color: #999;
                        padding-left: 30px;
                    }
                }
            }
        }
    }
</style>
